import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Footer, Home, About, Contact } from "./components";
import logo from './logos/1024LogoTransparentColor.svg';
import './css/fonts.css';
import './css/App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="landingPageTitle">routy</div>
        <div className="landingPageSubTitle">share your adventures</div>

      </header>
    </div>
  );
}

function AppMulti() {
  return (
    <div className="App">
    <Router>
      <Navigation />
      <Switch>
        <Route path="/" exact component={() => <Home />} />
        <Route path="/about" exact component={() => <About />} />
        <Route path="/contact" exact component={() => <Contact />} />
      </Switch>
      <Footer />
    </Router>



    </div>
  );
}

export default App;
